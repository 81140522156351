













































import { Vue, Component } from 'vue-property-decorator';
import Workspaces from "@/state/Workspaces";
import SalesTaxService from "@/services/SalesTaxService";
import SalesTaxPermitDTO from "@/dto/salestax/SalesTaxPermitDTO";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import CreateOrUpdateSalesTaxPermitRequestDTO from "@/dto/salestax/CreateOrUpdateSalesTaxPermitRequestDTO";
import FileUpload from "@/components/common/FileUpload.vue";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import {SalesTaxSubscriptionDTO} from "@/dto/salestax/SalesTaxSubscriptionDTO";
import SalesTaxSubscription from "@/components/salestax/SalesTaxSubscription.vue";
import RouteNames from "@/router/RouteNames";
import SalesTaxScheduleRecordDTO from "@/dto/salestax/SalesTaxScheduleRecordDTO";
import {COMPANY_ID, WORKFLOW_ID} from "@/constants/Paths";
import PaginationComponent from "@/components/util/PaginationComponent.vue";

const AppModule = namespace("App");

@Component({
  components: {PaginationComponent},
  methods: {
    WORKFLOW_ID() {
      return WORKFLOW_ID
    },
    COMPANY_ID() {
      return COMPANY_ID
    }
  }
})
export default class SalesTaxSubscriptionList extends Vue {


  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  private message = "";
  private successful = true;

  private schedule: Array<SalesTaxScheduleRecordDTO> = [];

  hideCreated = false;
  pageNumber = 1;
  totalPages = 1;
  pageSize = 40;

  mounted(){
    this.message = "";
    this.loadSchedules();
  }

  loadSchedules(){
    this.startLoading();
    return SalesTaxService.getSchedule(this.hideCreated, this.pageNumber, this.pageSize).then(
        response => {
          this.schedule = response.data.content;
          this.totalPages = response.data.totalPages;
          this.stopLoading();
        },
        error => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
          this.stopLoading();
        }
    );
  }

  get haveMarkedSchedules(): boolean{
    return this.schedule && !!this.schedule.find(s => s.createFlag == true);
  }

  createWorkflows(){
    this.startLoading();
    return SalesTaxService.createWorkflows(this.schedule.filter(s => !!s.createFlag).map(s => s.id)).then(
        response => {
          this.loadSchedules();
          this.stopLoading();
        },
        error => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
          this.stopLoading();
        }
    );
  }

  changePage(page: number){
    this.pageNumber = page;
    this.loadSchedules();
  }


}
